import * as React from 'react';
import {
  Box,
  Heading,
  Flex,
  Text,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import KeyVisualOthers from '../components/KeyVisualOthers';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { maxW } from '../assets/config';
import { StaticImage } from 'gatsby-plugin-image';
import RealStoreCard from '../components/RealStoreCard';
import JsonLd from '../components/JsonLd';

const Others = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Layout bg="black">
      <Seo
        title="Others"
        description="ChokerJokerについてのその他説明ページです。"
      />
      <JsonLd
        title="Others"
        description="ChokerJokerについてのその他説明ページです。"
      />
      <KeyVisualOthers />
      <Box bg="black">
        <Box px={4} maxW={maxW} mx="auto">
          <chakra.hr color="white" />
          <Flex justify="space-between" alignItems="flex-end" my={4}>
            <Heading
              color="white"
              fontSize={{ base: '4xl', xl: '6xl' }}
              fontFamily="Haetten, Sawarabi Gothic, sans-serif"
              letterSpacing={2}
              fontWeight={400}
            >
              Real Stores
            </Heading>
            <Text
              color="white"
              fontSize={{ base: '2xl', xl: '3xl' }}
              fontFamily="Haetten, Sawarabi Gothic, sans-serif"
              letterSpacing={2}
            >
              <u>And more ...</u>
            </Text>
          </Flex>
          <RealStoreCard
            storeName="美容室BIGHEAD"
            storeZipCode="335-0032"
            storeAddress="埼玉県東松山市新宿町24-16"
            storePhoneNumber="0493-24-5067"
            storeLink="https://bighead-world.com/"
            googleMapLink="https://g.page/bighead_3103?share"
          >
            <StaticImage src="../images/bighead.png" alt="bighead" />
          </RealStoreCard>
          <chakra.hr my={8} color="white" />
          <Flex pb={20} direction={isMobile ? 'column' : 'row'}>
            <Flex
              w={isMobile ? '100%' : '50%'}
              direction="column"
              mb={isMobile ? 20 : 0}
            >
              <Heading
                color="white"
                mb={isMobile ? 4 : 20}
                fontFamily="Haetten, Sawarabi Gothic, sans-serif"
                fontSize={{ base: '4xl', xl: '6xl' }}
                letterSpacing={2}
                fontWeight={400}
              >
                <Text
                  as="span"
                  color="orange"
                  fontSize={{ base: '4xl', xl: '6xl' }}
                >
                  Atelier
                </Text>{' '}
                & Company
              </Heading>
              <Box
                bg="white"
                w="90%"
                p={8}
                position="relative"
                mt={40}
                mx="auto"
              >
                <Box
                  position="absolute"
                  w={100}
                  top={-40}
                  left="50%"
                  transform="translateX(-50%)"
                >
                  <StaticImage src="../images/john_carry.png" alt="john" />
                </Box>
                <Heading fontSize="3xl" my={4}>
                  制作アトリエ及び会社概要
                </Heading>
                <Text fontSize="2xl">
                  商号：ジョンワークス合同会社
                  <br />
                  本社・制作アトリエ：埼玉県川越市山田1600-4
                  <br />
                  資本金：200万円
                  <br />
                  取引銀行：埼玉りそな銀行
                  <br />
                  Email：johnworks.tm@gmail.com
                  <br />
                  事業内容：
                  <br />
                  マネージメント事業、美容室のプロデュース、
                  <br />
                  運営支援事業、 Web制作、フランチャイズ事業、
                  <br />
                  CVSの運営、ネットショップの運営
                  <br />
                  <br />
                  新規事業は常に模索・継続中…
                </Text>
              </Box>
            </Flex>
            <Flex
              justify="center"
              alignItems={!isMobile ? 'flex-end' : 'center'}
              mb={!isMobile ? 20 : 0}
            >
              <Box w="90%" textAlign="center">
                <StaticImage src="../images/atelier.png" alt="atelier" />
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Layout>
  );
};

export default Others;
