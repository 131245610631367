import * as React from 'react';
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { maxW } from '../assets/config';
import { StaticImage } from 'gatsby-plugin-image';

export default function KeyVisualOthers() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg="black">
      <Flex
        maxW={maxW}
        mx="auto"
        pt={isMobile ? 0 : 8}
        justify={isMobile ? 'center' : 'space-between'}
        alignItems="center"
      >
        <Flex direction="column">
          <Text
            color="yellow"
            fontSize={{ base: '6xl', md: '9xl' }}
            fontFamily="Haetten, Sawarabi Gothic, sans-serif"
            letterSpacing={2}
            fontWeight={400}
          >
            Others{isMobile ? <br /> : null}
            <Text
              as="span"
              color="white"
              fontSize={{ base: '4xl', md: '6xl' }}
              textShadow="2px 3px 2px gray"
              ml={isMobile ? 0 : 8}
            >
              <u>Real Stores, Atrier & Company</u>
            </Text>
          </Text>
        </Flex>
        <Box w="20%">
          <StaticImage src="../images/cj-logo2.png" alt="logo" />
        </Box>
      </Flex>
      <Text
        color="white"
        fontSize="2xl"
        fontWeight="bold"
        px={8}
        pt={isMobile ? 10 : 0}
        pb={isMobile ? 10 : 20}
        maxW={isMobile ? '80%' : maxW}
        mx="auto"
      >
        当店は、実店舗での販売も行っております。
        宜しければ、ぜひこちらもお客様のご都合に合わせてご利用くださいませ。
      </Text>
    </Box>
  );
}
