import * as React from 'react';
import { Flex, Text, Box, useBreakpointValue } from '@chakra-ui/react';

export default function RealStoreCard({
  children,
  storeName,
  storeZipCode,
  storeAddress,
  storeLink,
  storePhoneNumber,
  googleMapLink,
  titleColor = '#EE827D',
}: {
  children: React.ReactNode;
  storeName: string;
  storeZipCode: string;
  storeAddress: string;
  storeLink: string;
  storePhoneNumber: string;
  googleMapLink: string;
  titleColor?: string;
}) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      bg="white"
      p={4}
      my={2}
      justify="space-between"
      alignItems="center"
      gap={4}
      direction={isMobile ? 'column' : 'row'}
    >
      <Flex
        justify={isMobile ? 'start' : 'center'}
        alignItems="center"
        flexGrow={1}
        w={isMobile ? '90%' : '30%'}
      >
        <Text
          textAlign={isMobile ? 'left' : 'center'}
          color={titleColor}
          fontSize={{ base: '4xl', md: '4xl', xl: '5xl' }}
          fontWeight="bold"
        >
          <a href={storeLink} target="_blank" rel="noopener noreferrer">
            <u>{storeName}</u>
          </a>
        </Text>
      </Flex>
      <Box w={isMobile ? '90%' : '40%'} textAlign="center">
        {children}
      </Box>
      <Flex
        justify="center"
        alignItems="center"
        flexGrow={1}
        direction="column"
        w={isMobile ? '90%' : '30%'}
      >
        <Text
          color="#A52A2A"
          fontSize={{ base: '2xl', sm: '3xl', md: '3xl', xl: '3xl' }}
          fontWeight="bold"
        >
          〒{storeZipCode}
          <br />
          {storeAddress}
          <br />
          ☎︎{storePhoneNumber}
          <br />
        </Text>
        <Text
          color="blue"
          fontWeight="bold"
          textAlign="center"
          fontSize={{ base: '2xl', md: '2xl', xl: '3xl' }}
        >
          →{' '}
          <a href={googleMapLink}>
            <u>Google MAP</u>
          </a>{' '}
          ←
        </Text>
      </Flex>
    </Flex>
  );
}
